<template>
  <div class="row">
    <div class="col-12">
      <input-date-only-btn
        ref="data"
        v-model="form.data"
        :label="$t('ACADEMICO.DATA')"
        required
      />
    </div>
    <div class="col-12">
      <input-text
        ref="descricao"
        v-model="form.descricao"
        type="text"
        :label="$t('ACADEMICO.AULA')"
        :placeholder="$t('ACADEMICO.AULA')"
        required
      />
    </div>
    <div class="col-12">
      <input-text-area
        ref="conteudo"
        v-model="form.conteudo"
        type="text"
        :label="$t('ACADEMICO.CONTEUDO_PROGRAMATICO')"
        :placeholder="$t('ACADEMICO.CONTEUDO_PROGRAMATICO')"
        required
      />
    </div>
  </div>
</template>
<script>
import {
  InputDateOnlyBtn,
  InputText,
  InputTextArea,
} from '@/components/inputs';
export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputDateOnlyBtn,
    InputText,
    InputTextArea,
  },
  mounted() {
    this.$emit('refs', this.$refs);
  },
};
</script>
