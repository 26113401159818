<template>
  <div>
    <b-table
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      :fields="fields"
      responsive="sm"
      :items="aulas"
      show-empty
      striped
      hover
    >
      <template #cell(data)="item">
        <template v-if="!editar.data">
          <span @click="editarCampo('data')">
            {{ formatarData(item.item.data) }}
          </span>
        </template>
        <template v-else>
          <input-text ref="data" v-model="item.item.data" />
          <b-button
            @click="cancelar(item.item, 'data')"
            variant="danger"
            class="mr-2"
            >{{ $t('GERAL.CANCELAR') }}</b-button
          >
          <b-button @click="salvar(item.item, 'data')" variant="primary">{{
            $t('GERAL.SALVAR')
          }}</b-button>
        </template>
      </template>

      <template #cell(descricao)="item">
        <template v-if="!editar.descricao">
          <span @click="editarCampo('descricao')">
            {{ item.item.descricao }}
          </span>
        </template>
        <template v-else>
          <input-text ref="descricao" v-model="item.item.descricao" />
          <b-button
            variant="secondary"
            class="mr-2"
            @click="cancelar(item.item, 'descricao')"
          >
            {{ $t('GERAL.CANCELAR') }}
          </b-button>
          <b-button variant="primary" @click="salvar(item.item, 'descricao')">
            {{ $t('GERAL.SALVAR') }}
          </b-button>
        </template>
      </template>

      <template #cell(conteudo)="item">
        <template v-if="!editar.conteudo">
          <span @click="editarCampo('conteudo')">
            {{ item.item.conteudo }}
          </span>
        </template>
        <template v-else>
          <input-text-area ref="conteudo" v-model="item.item.conteudo" />

          <b-button
            @click="cancelar(item.item, 'conteudo')"
            variant="secondary"
            class="mr-2"
            >{{ $t('GERAL.CANCELAR') }}</b-button
          >
          <b-button @click="salvar(item.item, 'conteudo')" variant="primary">{{
            $t('GERAL.SALVAR')
          }}</b-button>
        </template>
      </template>

      <template #cell(acoes)="item">
        <b-dropdown variant="outline-default">
          <b-dropdown-item @click="editarAula(item.item)">
            {{ $t('GERAL.EDITAR') }}
          </b-dropdown-item>
          <b-dropdown-item @click="excluir(item.item)">
            {{ $t('GERAL.EXCLUIR') }}
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </div>
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

// Components:
import { InputText, InputTextArea } from '@/components/inputs';

export default {
  props: {
    aulas: { type: Array, default: Array },
  },
  components: {
    InputText,
    InputTextArea,
  },
  data() {
    return {
      fields: [
        {
          key: 'data',
          label: 'Data',
          sortable: true,
        },
        {
          key: 'descricao',
          label: 'Aula',
          sortable: true,
        },
        {
          key: 'conteudo',
          label: 'Conteúdo Programático',
          sortable: true,
        },
        {
          key: 'acoes',
          label: 'Ações',
        },
      ],
      editar: {
        data: false,
        descricao: false,
        conteudo: false,
      },
    };
  },
  methods: {
    formatarData(data) {
      return helpers.formatarDataBr(data);
    },
    editarCampo(key) {
      this.editar[key] = true;
    },
    editarAula(item) {
      this.$emit('editar-aula', 'aula', item);
    },
    cancelar(key) {
      this.editar[key] = false;
    },
    salvar(key) {
      this.editar[key] = false;
    },
    excluir(item) {
      this.$emit('excluir', item.id);
    },
  },
};
</script>
