<template>
  <div>
    <titulo-btns title="Aulas">
      <b-button @click="openModal('aula')" class="mr-2" variant="primary"
        >Nova Aula</b-button
      >
      <b-button @click="irPara('academico-turma-x')" variant="danger"
        >Voltar para turma X</b-button
      >
    </titulo-btns>
    <tabela-aulas :aulas="tabela.items" @editar-aula="openModal" />

    <modal-aulas
      :form="modais.aulaDados"
      :exibir="modais.aula"
      @salvar="salvarAula"
      @fechar="closeModal('aula')"
    />
  </div>
</template>
<script>
// Utils & Aux:
import rotas from '@/common/utils/rotas';

// Models
import { Aula } from '@/common/models';

// Components:
import TituloBtns from '@/components/headers/TituloBtns';
import breadcrumb from '@/common/utils/breadcrumb';
import ModalAulas from '@/views/academico/diario-classe/components/aulas/Modal';
import TabelaAulas from '@/views/academico/diario-classe/components/aulas/Tabela';

export default {
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: 'Turma X - Disciplina X - Aulas' },
    ]);
  },
  components: {
    ModalAulas,
    TituloBtns,
    TabelaAulas,
  },
  data() {
    return {
      tabela: {
        fields: [
          {
            key: 'data',
            label: 'Data',
            sortable: true,
          },
          {
            key: 'nome',
            label: 'Aula',
            sortable: true,
          },
          {
            key: 'conteudo',
            label: 'Conteúdo Programático',
          },
          {
            key: 'acoes',
            label: 'Ações',
          },
        ],
        items: [
          {
            data: '09/02/2022',
            descricao: 'Programação letiva',
            conteudoProgramatico: '',
          },
        ],
      },
      modais: {
        aula: false,
        aulaDados: {},
        conteudoProgramatico: false,
        conteudoProgramaticoDados: {},
      },
    };
  },
  methods: {
    salvarAula() {
      this.closeModal('aula');
      this.modais.aulaDados = {};
    },
    visualizarConteudo(item) {
      this.modais.conteudoProgramaticoDados = item;
      this.modais.conteudoProgramatico = true;
    },
    visualizarAula() {},
    openModal(key, item) {
      this.modais[key] = true;
      item
        ? (this.modais[`${key}Dados`] = JSON.parse(JSON.stringify(item)))
        : (this.modais[`${key}Dados`] = new Aula({}));
    },
    closeModal(modal) {
      return (this.modais[modal] = false);
    },
    // FUNÇÕES AUXILIARES:
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
