<template>
  <modal
    id="modalAulas"
    :exibir="exibir"
    :titulo="'Aulas'"
    :campos="$refs"
    @fechar="fecharModal"
  >
    <aulas-form :form="form" @refs="getRefs" />

    <template v-slot:modal-footer>
      <div class="w-100 d-flex justify-content-between">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="salvar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import helpers from '@/common/utils/helpers';

// Components:
import Modal from '@/components/modal/Modal';
import AulasForm from '@/views/academico/diario-classe/components/aulas/Form';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  components: {
    AulasForm,
    Modal,
  },
  data() {
    return {
      refs: [],
    };
  },
  methods: {
    // FUNÇÕES VALIDAÇÃO FORMULÁRIO:
    getRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      return helpers.validarFormulario(this.refs);
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
    salvar() {
      if (!this.validarFormulario()) return;
      this.$emit('salvar');
    },
  },
};
</script>
