<template>
  <b-card>
    <disciplina-aulas />
  </b-card>
</template>
<script>
import breadcrumb from '@/common/utils/breadcrumb';
import DisciplinaAulas from '@/views/academico/cej/estrutura/disciplinas/components/aulas/Index.vue';
export default {
  mounted() {
    breadcrumb.definir(this.$store, [{ titulo: 'Turma X - Disciplina Y' }]);
  },
  components: {
    DisciplinaAulas,
  },
};
</script>
